//公共接口
//获取角色列表
import router from '@/router'
import Vue from 'vue'

let list = null

async function getuserlist(type) {
  list  = []
  let params = {}
  if(type === 1){
    params={
      user_type: 1,
      user_status:1,
      guard_name:'web'
    }
  }else if(type === 2){
    params={
      user_type: 1,
      guard_name:'web'
    }
  }
 await  Vue.prototype.$axios.get('/api/system/user/list',{
  params
 }).then(response=>{
    list = response
  })
  return list
}

//获取客户信息
 async function getCustomer(type,data){
   list = []
   let params = {} 
   if(type == 1){
    params.customer_status=1
    params.check_status=1
   }else if(type == 2){
    params.customer_status=1
   }else if(type == 3){
    params = data
   }
   await Vue.prototype.$axios.get('/api/customer/list',{params}).then(res=>{
    list = res
   })
   return list
 }


//获取角色列表
async function getRole(params){
   list = null
  await Vue.prototype.$axios.get('/api/system/role/list',{
    params
  }).then(response=>{
      list = response
  })

  return list
}

//部门列表
async function getDeptlist(params){
  list  = null 
  await Vue.prototype.$axios.get('/api/system/dept/list',{
    params
  })
  .then(response=>{
    list = response 
  })
  return list
}

//销货通知单列表

async function getNoticeorder(params){
  list = null
  await Vue.prototype.$axios.get('/api/saleorder/notice_order/list',{
    params
  })
  .then(response=>{
    list = response
  })
  return list
}

//收款单列表
async function getReceipt(params){
  list  = []
   await Vue.prototype.$axios.get('/api/finance/receipt/list',{
     params
   })
   .then(response=>{
     list = response
   })
   return list
 }

 //定金转贷款单据
async function GetdepositList(params){
  list  = []
   await Vue.prototype.$axios.get('/api/finance/deposit/list',{
     params
   })
   .then(response=>{
     list = response
   })
   return list
 }


   


 //商品列表
async function getGoods(params){
  list  = []
   await Vue.prototype.$axios.get('/api/goods/list',{
     params
   })
   .then(response=>{
     list = response
   })
   return list
 }


 //省市区接口
 async function getarealist(){
  let options = []
  await Vue.prototype.$axios.get('/api/common/misc/china_area')
  .then(res=>{
        let data = res.data.data
        if(res.data.status_code === 200){
          options = data
        }
      })
      return options
    }

  //时间转换
  function upadaDate(account_at){
          let date = new Date(Number(account_at));//时间戳为10位需*1000，时间戳为13位的话不需乘1000
          console.log('date',date)
          let Y = date.getFullYear() + '-';
          let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
          let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
          let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
          let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
          let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
          account_at =  Y + M + D + h + m + s;
          return account_at
  }
  //将时间转成时间戳在将时间戳转换成 年月日时间格式 在截取出年月的时间
  function UpDataTime(time){
    let timestamp = Date.parse(new Date(time).toString());
    //转换年月日
    let data = upadaDate(timestamp)
    return data
  }



  //NC代码
 async function  getSelect() {
  //循环对象取值,合并数组
  let mergedArray = [];
   await  Vue.prototype.$axios.get("/api/common/misc/customer/channel").then((res) => {
      if (res.data.status_code === 200) {
        let data = res.data.data;
        for (let i in data) {
          mergedArray = mergedArray.concat(data[i]);
        }
      }
    })
    return mergedArray;
  }

  //渠道
  async function  getChannel() {
    let ChannelArray = [];
    await  Vue.prototype.$axios.get("/api/common/misc/store/channel").then((res) => {
        if (res.data.status_code === 200) {
           for(let key in res.data.data){
            if(res.data.data[key].length != 0){
              let list = {
                label:res.data.data[key],
                value : key
             }
             ChannelArray.push(list)
            }
           }
        }
      })
    return ChannelArray;
    }

  //中交福利 平台状态
   async function  zhongjiaostatus() {
    let ChannelArray = [];
    await  Vue.prototype.$axios.get("/api/common/misc/zhongjiao/status").then((res) => {
        if (res.data.status_code === 200) {
           for(let key in res.data.data){
            if(res.data.data[key].length != 0){
              let list = {
                label:res.data.data[key],
                // value : Number(key) 
                value : key
             }
             ChannelArray.push(list)
            }
           }
        }
      })
    return ChannelArray;
    }

    //中交福利 推送状态
   async function  zhongjiaopushstatus() {
    let ChannelArray = [];
    await  Vue.prototype.$axios.get("/api/common/misc/zhongjiao/push/status").then((res) => {
        if (res.data.status_code === 200) {
           for(let key in res.data.data){
            if(res.data.data[key].length != 0){
              let list = {
                label:res.data.data[key],
                // value : Number(key) 
                value : key
             }
             ChannelArray.push(list)
            }
           }
        }
      })
    return ChannelArray;
    }


//仓库
  async function  getWarehouse() {
    let Warehouse = [];
    await  Vue.prototype.$axios.get("/api/common/misc/warehouses").then((res) => {
        if (res.data.status_code === 200) {
           for(let key in res.data.data){
            if(res.data.data[key].length != 0){
              let list = {
                label:res.data.data[key],
                value : key
             }
             Warehouse.push(list)
            }
           }
        }
      })
    return Warehouse;
    }



    async function  getE3Status() {
      //循环对象取值,合并数组
      let E3StatusList = [
        { label: "初始", value: 0 },
        { label: "成功", value: 1 },
        { label: "失败", value: 2 },
      ];
        return E3StatusList;
      }

    //业务类型
    async function  getBusiness() {
      let BusinessArray = [];
      await  Vue.prototype.$axios.get("/api/common/misc/business/type").then((res) => {
          if (res.data.status_code === 200) {
            for(let key in res.data.data){
              if(res.data.data[key].length != 0){
                let list = {
                  name:res.data.data[key],
                  value : key
              }
              BusinessArray.push(list)
              }
            }
          }
        })
      return BusinessArray;
      }
  
  //收款单-收款单类型数据
  async function  getReceiptTypeList() {
    let receiptTypeList = [];
    await  Vue.prototype.$axios.get("/api/common/misc/receipt/type").then((res) => {
        if (res.data.status_code === 200) {
           for(let key in res.data.data){
            if(res.data.data[key].length != 0){
              let list = {
                name:res.data.data[key],
                value : key
             }
             receiptTypeList.push(list)
            }
           }
        }
      })
    return receiptTypeList;
    }

    
  //E3全部快递公司
  async function getE3ExpressCompany(){
    let E3ExpressCompany = [];
    await  Vue.prototype.$axios.get("/api/common/misc/shipping/list").then((res) => {
        if (res.data.status_code === 200) {
           for(let key in res.data.data){
            if(res.data.data[key].length != 0){
              let list = {
                label:res.data.data[key],
                value : key
             }
             
             E3ExpressCompany.push(list)
            }
           }
        }
      })
    return E3ExpressCompany;
  }


  //调整单- 账款类型接口数据
  async function  getAccountTypeList() {
    let accountTypeList = [];
    await  Vue.prototype.$axios.get("/api/common/misc/account/type").then((res) => {
        if (res.data.status_code === 200) {
           for(let key in res.data.data){
            if(res.data.data[key].length != 0){
              let list = {
                name:res.data.data[key],
                value : key
             }
             
             accountTypeList.push(list)
            }
           }
        }
      })
    return accountTypeList;
    }
  
  //获取用户部分字段 /api/system/user/index
  async function  geteEasyUser(type) {
    let EasyUserList = [];
    let params = {}
    //业务 内部员工  状态为启用
    if(type === 1){
      params={
        user_type: 1,
        user_status:1,
        guard_name:'web'
      }
    }else if(type === 2){
      //制单人，内部员工
      params={
        user_type: 1,
        guard_name:'web'
      }
    }
    await  Vue.prototype.$axios.get("/api/system/user/index",{params}).then((res) => {
        EasyUserList = res.data.data
      })
    return EasyUserList;
    }


  //客户列表- 可用款类型下拉数据
  async function  getAvailableTypeList() {
    let availableTypeList = [];
    await  Vue.prototype.$axios.get("/api/common/misc/available/funds/type").then((res) => {
        if (res.data.status_code === 200) {
           for(let key in res.data.data){
            if(res.data.data[key].length != 0){
              let list = {
                name:res.data.data[key],
                value : key
             }
             availableTypeList.push(list)
            }
           }
        }
      })
    return availableTypeList;
    }

  //店铺
  async function  getShops() {
    let shopsList = null;
   await  Vue.prototype.$axios.get("/api/goods/misc/store").then((res) => {
      if (res.data.status_code === 200) {
        shopsList = res.data.data;
      }
    })
    return shopsList;
    }


     //一件代发 下拉类型选择
  async function  getOrderType() {
    //循环对象取值,合并数组
    let OrderTypeList = [
      { name: "一件代发", value: 1 },
      { name: "批采", value: 2 },
      { name: "定制", value: 3 }
    ];
   
      return OrderTypeList;
    }


  //发货类型
  async function  getShippingType() {
    //循环对象取值,合并数组
    let ShippingTypeList = [
      { name: "仓库发货", value: 1 },
      { name: "工厂代发", value: 2 },
    ];
      return ShippingTypeList;
    }

   function ExprotScan(list){
    let data = JSON.parse(JSON.stringify(list));
    let str = "";
    let time = "";
    for (let [k, v] of Object.entries(data)) {
      if (k === "page" || k === "page_size") {
        continue;
      } else if (k.indexOf('start') !== -1 || k.indexOf('end')!== -1 || k.indexOf('time')!== -1 || k.indexOf('_at')!== -1) { 
         time += "&" + `${k}=${v}`
      }else if(v instanceof Array){
        if(v.length !== 0){
          for(let i in v){
            str += "&" +`${k}[]=${v[i]}`;
          }
        }
      }
      else {
        str += "&" + `${k}=${v}`;
      }
    }

    str = str.replace("&", "");
    // str = str.replace(/\s*/g, "");
    str += time;
    

    return str
  }

  //用户快捷登录
  async function ConvenientLogin(id){
    let list = null
    await  Vue.prototype.$axios.get(`/api/system/user/convenient/login/${id}`).then((res) => {
      if (res.data.status_code === 200) {
        list = res.data.data
      }
    })
    return  list;
  }

  //登记下拉列表
  async function getGradeList(id){
    let list = null
    await  Vue.prototype.$axios.get(`/api/common/misc/customer/graded`).then((res) => {
      if (res.data.status_code === 200) {
        list = res.data.data
      }
    })
    return  list;
  }

   //销货通知单 审核状态
   async function getReviewstatus(){
    let list = null
    await  Vue.prototype.$axios.get(`/api/common/misc/audit_status`).then((res) => {
      if (res.data.status_code === 200) {
        list = res.data.data
      }
    })
    return  list;
  }

  //销货通知单 平台订单状态
  async function getsale_notice_order_SelectList(){
    let data = []
    await  Vue.prototype.$axios.get(`/api/common/misc/zhongjiao/sale_notice_order/status`).then((res) => {
      if (res.data.status_code === 200) {
        for(let key in res.data.data){
          if(res.data.data[key].length != 0){
            let list = {
              label:res.data.data[key],
              value : key
           }
           data.push(list)
          }
         }
      }
    })
    return  data;
  }

  //获取版本号
  async function getVersion(){
    let version
    if(localStorage.getItem('version') ){
      version = localStorage.getItem('version')
    }
    await Vue.prototype.$axios.get('/api/version').then(res=>{
      if(res.status == 200){
        if(version && version != res.data.version){  
          window.location.reload(true)
        }
        localStorage.setItem('version',res.data.version)
      }
      
    })
  }


//判断是否调用了 /api/saleorder/notice_order/batch/confirm 接口参数添加time:当前时间戳
function isAddTime(params){
  params.time = new Date().getTime()
}

  //页面封装弹窗 比如 删除 审单 之类的
 async function publicConfirm(title,url,params,pathtype,type){
  return new Promise((resolve,reject)=>{
    let Click_num = null
    Vue.prototype.$confirm(title, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
      showCancelButton: true,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      beforeClose: (action, instance, done) => {
        if(action === 'confirm'){
          //判断是否调用 
          if(url == '/api/saleorder/notice_order/batch/confirm' && Click_num == null){
            isAddTime(params)
            Click_num = 1
          }
          if(pathtype === 'delete'){
            instance.confirmButtonLoading = true;
          Vue.prototype.$axios.delete(url,{
            params
          })
            .then((res) => {
              if (res.data.status_code === 200) {
                if(type){
                  Vue.prototype.$message({
                      message: res.data.message,
                      type: "success",
                    });
                }
                  done()
                  resolve (res.data) 
              } else if (res.data.status_code === 500) {
                reject( res.data)
                done()
              }
            }).finally(() => {
              instance.confirmButtonLoading = false;
              done()
            })
          }else{
            instance.confirmButtonLoading = true;
            Vue.prototype.$axios.post(url,params)
              .then((res) => {
                if (res.data.status_code === 200) {
                    if(res.data.data && type){
                      Vue.prototype.$message({
                        message: res.data.message,
                        type: "success",
                      });
                    }
                    done()
                    resolve (res.data) 
                } else if (res.data.status_code === 500) {
                  reject( res.data)
                  done()
                }
              }).finally(() => {
                instance.confirmButtonLoading = false;
                done()
              })
            }
        }else{
          done()
        }
      }
    })
  })
}





//退货通知单列表
async function GetBack_order_notice_list (params){
  list  = []
   await Vue.prototype.$axios.get('/api/back_order/notice/list',{
     params
   })
   .then(response=>{
     list = response
   })
   return list
}

//获取销货单列表
async function GetOrdinedivendita(data){
  let Ordinedivendita = {}
  await Vue.prototype.$axios.get('/api/saleorder/goods_list',{
    params:data
  }).then(res=>{
    if(res.data.status_code === 200){
      Ordinedivendita.data = res.data.data.list
      Ordinedivendita.total = res.data.data.totle
    }
  })
  return Ordinedivendita

 }

//处理创建退货通知单的提交数据
 function updataOrderList(data){
  let  retData = []
  data.map(item=>{
    let {goods_sn ,e3_order_sn,sku,price,image,colour,size} =  {...item}
    let name ,store_id ,number ,return_quantity
    item.goods_name  ? name = item.goods_name  : name = ''
    item.store_id ? store_id = item.store_id  : store_id = ''
    return_quantity  = item.return_number
    e3_order_sn == undefined ? e3_order_sn = '' : e3_order_sn
    item.goods_stock ? number = item.goods_stock : ''
    let list = {
      goods_sn,
      e3_order_sn,
      sku,
      price,
      number,
      image,
      colour,
      size,
      name,
      store_id,
      return_quantity
    }
    retData.push(list)             
  })
  return retData
 }



//订单复制弹窗
function dingdanBox (order_id,title){
  Vue.prototype.$confirm(title, "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
    showCancelButton: true,
    closeOnClickModal: false,
    closeOnPressEscape: false,
    beforeClose: (action, instance, done) => {
      if(action === 'confirm'){
          instance.confirmButtonLoading = true;
          let is =  Ordercopying(order_id)
          if(is ){
            instance.confirmButtonLoading = false 
          }
      }
      done()
    }
  })
}

 //订单复制
 async function Ordercopying(order_id){
  let is = 0
  let query = {}
  let order_sn = ''
 await Vue.prototype.$axios.post('/api/saleorder/notice_order/copy',
        order_id
      ).then(res=>{
        if(res.data.status_code === 200){
          is = 1
           query = {
            edit: 1,
            order_sn: res.data.data.order_sn
          }
          order_sn = res.data.data.order_sn
        }
    })
    if(is == 1){
      return  publicMessageBox(`新销货通知单：${order_sn} 马上打开？`,'/salesnotice/DetailsCreatesalesnotice',query)
    }
 }
 //销货通知单——复制单据 和 销货单详情——生成退货通知单 弹窗
 function publicMessageBox(title,path,query,){
  Vue.prototype.$confirm(title, "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "success",
    showCancelButton: true,
    closeOnClickModal: false,
    closeOnPressEscape: false,
    beforeClose: (action, instance, done) => {
      if(action === 'confirm'){
          router.push({
            path,
            query
          })
        done()
      }else{
        done()
      }
    }
  })
 }

 //对处理数据接口返回成功状态，但是data中返回处理错误的提示
 function dataMessageError(data){
  Object.keys(data).forEach(key=>{
    setTimeout(() => {
      Vue.prototype.$message.error(data[key])
    }, 100);
    })
 }


//抛出 定义的方法
export default {
  getuserlist,
  getCustomer,
  getRole,
  getDeptlist,
  getNoticeorder,
  getReceipt,
  GetdepositList,
  getGoods,
  getarealist,
  upadaDate,
  UpDataTime,
  getSelect,
  getChannel,
  zhongjiaostatus,
  zhongjiaopushstatus,
  getShops,
  getReceiptTypeList,
  getOrderType,
  ExprotScan,
  ConvenientLogin,
  isAddTime,
  getGradeList,
  getVersion,
  getAccountTypeList,
  getAvailableTypeList,
  publicConfirm,
  getBusiness,
  GetBack_order_notice_list,
  getWarehouse,
  updataOrderList,
  GetOrdinedivendita,
  getReviewstatus,
  getsale_notice_order_SelectList,
  geteEasyUser,
  getE3ExpressCompany, 
  getShippingType,
  dingdanBox ,
  Ordercopying,
  publicMessageBox,
  getE3Status,
  dataMessageError,
}