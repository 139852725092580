import axios from 'axios'
import baseURL from '../../api/axios/baseUrl'
import store from '@/store'
import router from '@/router'
import Vue from 'vue'

function getUserNav() {
  Vue.prototype.$axios
    .get("/api/system/user/detail", {
      params: {
        id: localStorage.getItem("user_id"),
        permission_tree : 1,//树状
      },
    })
    .then((res) => {
      let data = res.data.data 
      if(data.permissions){
          sessionStorage.setItem('user_type',JSON.stringify(res.data.data.user_type))
          store.commit('updataUserType',res.data.data.user_type) 
          getNavTab(res.data.data.permissions)
          let is = sessionStorage.getItem('getUserNav')
          if(is == 1){
            setTimeout(() => {
              Vue.prototype.$message.success('登录成功');
          }, 0);
            router.push('/index')
            location.reload(true)
            sessionStorage.setItem('getUserNav',0)
          }
      }else{
         store.commit('clreatStroeData',1)
         Vue.prototype.$alert('此账户没有权限','提示',{
          confirmButtonText: '确定',
          callback: action => {
            router.push('/login')
          }
         })
      }
    });
}
/** 筛选出数据中的 菜单, 菜单的子菜单
 * permission_type =  1 表示菜单
 * permission_type =  2 表示菜单的子菜单
 * 1.先遍历所有对象  先找出菜单  判断 permission_type =  1
 * 1.遍历所有对象  先找出菜单的子菜单  判断 permission_type =  2
 *  */
function getNavTab(data) {
  let NavListData = []
    for(let n in data){
        // title:菜单的名称    id 菜单的 id  name 菜单的标识  path 菜单的路径
        data[n].title = data[n].name
        data[n].xuhao = data[n].id
        data[n].name = data[n].id.toString()
        //一级菜单是点击的菜单  比如 导出下载
        if(data[n].web_path != undefined){
            data[n].path = data[n].web_path
            data[n].child = data[n].children
            delete data[n].children
            let a = {
                Plabel : data[n].title,
                path:data[n].web_path
            }
            NavListData.push(a)
            
        }
        //判断是否有子菜单
        if(data[n].children){
            //循环子菜单  更改数据
            for(let s in data[n].children){
                data[n].children[s].title = data[n].children[s].name
                data[n].children[s].xuhao = data[n].children[s].id
                data[n].children[s].name = data[n].children[s].id.toString()
                data[n].children[s].path = data[n].children[s].web_path
                let a = {
                    Plabel : data[n].title,
                    label:data[n].children[s].title,
                    path:data[n].children[s].web_path
                }
                NavListData.push(a)
            }
        }
    }
    //将退单下拉菜单中的菜单反转位置
    data.map(item=>{
      if(item.id == 77){
         item.children.reverse()
      }
    })
    
    //6.26 把所有菜单信息保存到 session中 然后每次路由发生变化的时候就去 调用store中的方法
    sessionStorage.getItem('caidanxinxi') ?  ''  : Vue.prototype.bus.$emit('getNavList',data)
    // for (const key in data) {
    //   if(data[key].id == 88 && data[key].title == '财务管理'){
    //       if(data[key].children){
    //         [data[key].children[2] , data[key].children[3] , data[key].children[4]] = [data[key].children[4] , data[key].children[2] , data[key].children[3]]
    //       }
    //   }
    // }
    //+
    //-
    //-
    sessionStorage.setItem('caidanxinxi',JSON.stringify(data))
    store.commit('getNavClickPath',NavListData)
    //获取是不是第一次登录
    let isOne = sessionStorage.getItem('isOne')
    if(isOne == 1){
      router.push('/index')
      sessionStorage.setItem('isOne',0)
    }
}
export default {
  getUserNav
}